<template>
  <div class="setup">
    <div class="setup_heard">
      <div>
        <div class="setud-div">
          <p style="font-weight: bold;">三方设置</p>
          <div class="setud-div-01">
            <p>是否开启</p>
            <p>操作</p>
          </div>
        </div>
        <div v-if="irty">
          <!-- <span style="color:#333333;margin-left:50px;">通知渠道</span> -->
          <div v-for="(item, index) in arrange" :key="index" class="setup_content">
            <div style="display:flex">
              <img style="margin-left:20px;" :src="item.iopon" alt />
              <div class="setup_content_left">
                <span class="rightspan">{{ item.message }}</span>
                <p class="bottomp">{{ item.botttext }}</p>
              </div>
            </div>

            <div class="div-kai">
              <el-switch @change="kaibtn($event, index)" v-model="item.value"
                v-if="hasPerm(['setting.trd_service.update'])"></el-switch>
              <el-button @click="editbut(index)" v-if="hasPerm(item.permission)">编辑</el-button>
            </div>
          </div>
          <a href="https://wx.mail.qq.com/" target="_blank" class="leftspan">立即申请</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      irty: false,
      value: null,
      arrange: [
        {
          message: "短信接口配置",
          botttext:
            "提供专业、快速、低成本的短信平台发送和短信接口，满足各类企业需求",
          value: "",
          iopon: require("@/assets/icon/group_17_back.svg"),
          permission: ['setting.trd_service.sms.update'],
        },
        {
          message: "对象存储配置",
          botttext: "阿里云、腾讯云提供海量、安全、低成本、高可靠的云存储服务",
          value: "",
          iopon: require("@/assets/icon/group_16_back.svg"),
          permission: ['setting.trd_service.file_storage.update'],
        },
        {
          message: "物流配置",
          botttext: "一站式物流服务,为开发者聚合1600+物",
          value: "",
          iopon: require("@/assets/icon/group_15_back.svg"),
          permission: ['setting.trd_service.express.update'],
        },
        {
          message: "邮件通知配置",
          botttext: "绑定企业微信后可以选择企业组织架构人员、",
          value: "",
          iopon: require("@/assets/icon/mail.svg"),
          permission: ['setting.trd_service.mail.update'],
        }
        // {
        //   message: "公众号配置",
        //   botttext: "提供专业、快速、低成本的短信平台发送和短",
        //   iopon: require("@/assets/icon/group_15_back_2.svg")
        // }
      ]
    };
  },
  mounted() {
    this.keyninit();
  },
  methods: {
    kaibtn(e, index) {
      //console.log(e, index);
      if (index == 0) {
        let data = {
          "sms.open": e != true ? 0 : 1
        };
        this.$put(this.$apis.switchk, data).then(res => {
          if (res.code == 200) {
            this.$message({
              message: "修改成功",
              type: "success"
            });
          } else {
            this.$message({
              message: "修改失败",
              type: "error"
            });
          }
        });
      } else if (index == 1) {
        let data = {
          "file_storage.open": e != true ? 0 : 1
        };
        this.$put(this.$apis.switchk, data).then(res => {
          if (res.code == 200) {
            this.$message({
              message: "修改成功",
              type: "success"
            });
          } else {
            this.$message({
              message: "修改失败",
              type: "error"
            });
          }
        });
      } else if (index == 2) {
        let data = {
          "express.open": e != true ? 0 : 1
        };
        this.$put(this.$apis.switchk, data).then(res => {
          if (res.code == 200) {
            this.$message({
              message: "修改成功",
              type: "success"
            });
          } else {
            this.$message({
              message: "修改失败",
              type: "error"
            });
          }
        });
      } else {
        let data = {
          "mail.open": e != true ? 0 : 1
        };
        this.$put(this.$apis.switchk, data).then(res => {
          if (res.code == 200) {
            this.$message({
              message: "修改成功",
              type: "success"
            });
          } else {
            this.$message({
              message: "修改失败",
              type: "error"
            });
          }
        });
      }
    },
    // 初始化获取第三方按钮显示
    keyninit() {
      this.$get(this.$apis.switchk)
        .then(res => {
          if (res.code == 200) {
            //console.log(res);
            this.arrange.forEach((item, index) => {
              if (index == 0) {
                item.value = res.data["sms.open"];
                if (item.value == 0) {
                  item.value = false;
                } else {
                  item.value = true;
                }
              } else if (index == 1) {
                item.value = res.data["file_storage.open"];
                if (item.value == 0) {
                  item.value = false;
                } else {
                  item.value = true;
                }
              } else if (index == 2) {
                item.value = res.data["express.open"];
                if (item.value == 0) {
                  item.value = false;
                } else {
                  item.value = true;
                }
              } else {
                item.value = res.data["mail.open"];
                if (item.value == 0) {
                  item.value = false;
                } else {
                  item.value = true;
                }
              }
            });
            this.irty = true;
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch(err => {
          this.common.message(this, err.message);
        });
    },
    editbut(index) {
      //console.log(index)
      if (index == 0) {
        this.$router.push("/setup/textmessage");
      } else if (index == 1) {
        this.$router.push("/setup/obctstore");
      } else if (index == 2) {
        this.$router.push("/setup/circulation");
      } else {
        this.$router.push("/setup/mail");
      }
    }
  }
};
</script>

<style lang="less" scoped>
.setup {

  // position: relative;
  // .backdrop{
  //   width: 100%;
  //   height: 50px;
  //   background: #F9F9FC;
  //   position: absolute;
  // }
  .setup_heard {
    position: relative;

    // display: flex;
    // justify-content: space-between;
    .leftspan {
      position: absolute;
      top: 384px;
      left: 230px;
      margin-left: 24px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: var(--fontColor, #fb6638);
      cursor: pointer;
    }

    .setud-div {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      height: 50px;
      background: #f9f9fc;

      p {
        font-weight: 800;
        margin-left: 20px;
      }

      .setud-div-01 {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 400px;

        p {
          font-weight: bold;
          margin-right: 60px;
        }
      }

      p {
        // margin-left: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0px;
      }
    }

    width: 100%;
    border-radius: 4px;
    margin-bottom: 20px;
    background: #fff;
    padding: 20px 20px 40px 20px;
    box-sizing: border-box;

    .setup_content {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
      height: 70px;
      border-bottom: 1px solid #f2f2f2;

      .div-kai {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        width: 370px;

        .el-switch {
          margin-top: -25px;
        }

        .el-button {
          width: 88px;
          height: 38px;
          margin-top: -30px;
          margin-right: 30px;
        }
      }

      .setup_content_left {
        width: 500px;
        margin-left: 56px;
        position: relative;

        .rightspan {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }

        .bottomp {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          width: 800px;
          margin-top: 6px;
        }
      }

      img {
        width: 49px;
        height: 53px;
      }
    }

    p {
      margin-top: 27px;
    }

    .setup_edit {
      width: 28px;
      height: 40px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-top: 50px;
      cursor: pointer;
    }
  }
}
</style>